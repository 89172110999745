import React from "react";

class Page extends React.Component {
  render() {
    return (
      <div>
        <header>
          <div className="page_width rel">
            <nav>
              <a href="/" id="logo" />
              <span id="menu_btn" />
              <ul id="menu">
                <li>
                  <a href="/home/">Strona główna</a>
                </li>
                <li>
                  <a href="/produkty/">Produkty</a>
                </li>
                <li>
                  <a href="/poradnik/">Poradnik</a>
                </li>
                <li>
                  <a href="/dobierz-lek/">Test</a>
                </li>
                <li>
                  <a href="/kontakt/">Kontakt</a>
                </li>
                <li>
                  <a href="/pytania-i-odpowiedzi/">Pytania i odpowiedzi</a>
                </li>
              </ul>
            </nav>
          </div>
        </header>
        <div id="page" className="page_width">
          <div id="breadcrumbs">
            <a href="/">Strona główna</a>
            <a href="/poradnik/#przeziebienie"> &gt; Poradnik(Przeziębienie)</a>
            <a
              href="/poradnik/przeziebienie/domowe_sposoby_na_walke_z_przeziebieniem/"
              className="sel"
            >
              {" "}
              &gt; Domowe sposoby na walkę z przeziębieniem
            </a>
          </div>
          <div id="page_content">
            <div className="col1">
              <div className="main_photo">
                <img src="/web/kcfinder/uploads/files/artykuly/GettyImages-1030072086.jpg" />
              </div>
            </div>
            <div className="col2">
              <h1>Domowe sposoby na walkę z przeziębieniem</h1>
              <div className="wysiwyg">
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  <b>
                    Herbata z dodatkiem imbiru, czosnku lub miodu oraz
                    tradycyjny rosół to domowe sposoby, które pomogą nam pokonać
                    przeziębienie. Sprawdzone metody przekazywane z pokolenia na
                    pokolenie pozwalają zapobiec infekcjom, a nawet wygrać walkę
                    z chorobą, złagodzić katar, kaszel i dreszcze.&nbsp;
                  </b>
                </p>
                <p
                  className="p2"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  &nbsp;
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  <a href="/slowniczek/#przeziebienie" target="_blank">
                    Przeziębienie
                  </a>{" "}
                  dopada każdego z nas przynajmniej raz w roku, najczęściej w
                  okresie jesienno-zimowym. Zmienne warunki atmosferyczne,
                  krótszy sen czy wyziębienie organizmu mogą stanowić przyczynę
                  częstych infekcji. Ich główne objawy to: uciążliwy katar, ból
                  głowy, podwyższona temperatura, kaszel i ból gardła. By je
                  załagodzić warto zastosować domowe sposoby.
                </p>
                <p
                  className="p2"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  &nbsp;
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  Pierwsze miejsce w wielu domach zajmuje herbata z imbirem,
                  cytryną lub czosnkiem. Alternatywnie stosuje się mleko z
                  miodem bądź czosnkiem, gdyż te składniki posiadają wiele
                  dobroczynnych właściwości. Imbir działa przeciwzapalnie,
                  przeciwbakteryjnie, a dodatkowo, w niektórych przypadkach,
                  antywirusowo. Cytryna to źródło witaminy C. Czosnek - zabójca
                  bakterii (bywa nazywany naturalnym antybiotykiem), regeneruje
                  komórki{" "}
                  <a href="/slowniczek/#uklad_immunologiczny" target="_blank">
                    układu immunologicznego
                  </a>{" "}
                  oraz wzmacnia organizm, a dzięki allicynie uwalnianej podczas
                  zgniatania ząbków, działa przeciwgrzybiczo. Z kolei miód
                  zapewnia ochronę przed drobnoustrojami, ma właściwości
                  bakteriostatyczne i bakteriobójcze.
                </p>
                <p
                  className="p2"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  &nbsp;
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  Nasze mamy i babcie przeziębienie leczyły też syropem z
                  cebuli, gdyż zawiera on wiele witamin (m.in. A, C, PP)
                  działających dezynfekująco i łagodząco. Syrop domowej roboty
                  uzupełnia niedobory witamin B1, B2 i C oraz mikroelementów
                  (np. cynku wzmacniającego odporność przeciwwirusową czy
                  fosforu będącego budulcem kości). &nbsp;
                </p>
                <p
                  className="p2"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  &nbsp;
                </p>
                <p
                  className="p2"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  <img
                    alt="Domowe sposoby i leki na przeziębienie. "
                    src="/web/kcfinder/uploads/images/shutterstock_327254051.jpg"
                    style={{
                      width: "380px",
                      height: "333px",
                      float: "left",
                      margin: "5px 10px 10px 0px",
                      "border-width": "2px",
                      "border-style": "solid",
                    }}
                  />
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  &nbsp;
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  Podczas walki z przeziębieniem nie można zapomnieć o ziołach,
                  które zalecane są zwłaszcza w postaci naparów. Warto
                  przygotować sobie inhalacje np. z herbaty z kwiatu lipy
                  (działa napotnie), kwiatów i owoców czarnego bzu (posiadają
                  udowodnione właściwości przeciwzapalne) czy rumianku z
                  tymiankiem i olejków eterycznych na bazie roślin, np.
                  anyżowego i eukaliptusowego (zabijają bakterie oraz
                  rozgrzewają organizm).
                </p>
                <p
                  className="p2"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  &nbsp;
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  Wśród domowych sposobów na pokonanie choroby znajduje się też
                  rosół z kury. Gotujący się rosół uwalnia cysteinę (aminokwas
                  wchodzący w skład białek), której wdychanie przyspiesza
                  odkrztuszanie wydzieliny znajdującej się w płucach. Zupa
                  rozgrzewa i wzmacnia, a para buchająca znad talerza udrożnia
                  zatkany nos.
                </p>
                <p
                  className="p2"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  &nbsp;
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  &nbsp;
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  &nbsp;
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  Zablokowany nos, utrudnia codzienne funkcjonowanie.{" "}
                  <a href="/slowniczek/#obrzek_blony_sluzowej" target="_blank">
                    Opuchnięta błona śluzowa
                  </a>{" "}
                  utrudnia odplyw wydzieliny z nosa, a przez to blokuje swobodne
                  oddychanie i w konsekwencji powoduje uczucie ucisku w&nbsp;
                  głowie i zatokach. W wyniku kataru mamy problemy z
                  zasypianiem, budzimy się w nocy, przez co w ciągu dnia czujemy
                  się rozdrażnieni i osłabieni. W walce z katarem dobrze
                  sprawdzą się leki, które odblokowują zatkany nos, np. te
                  dostępne pod marką Sudafed<sup>®</sup>. Do wyboru mamy leki
                  pod różnymi postaciami – aerozoli do nosa lub tabletek. W
                  aerozolach{" "}
                  <a href="/produkty/sudafed_xylospray/" target="_blank">
                    Sudafed<sup>®</sup> XyloSpray
                  </a>{" "}
                  lub{" "}
                  <a href="/produkty/sudafed_xylospray_ha/" target="_blank">
                    Sudafed<sup>®</sup> XyloSpray HA
                  </a>{" "}
                  substancją czynną jest{" "}
                  <a href="/slowniczek/#ksylometazolina" target="_blank">
                    ksylometazolina
                  </a>
                  , która poprzez zwężenie naczyń krwionośnych zaczyna
                  odblokowywać nos już po około 5-10 minutach. Z kolei{" "}
                  <a href="/produkty/sudafed_tabletki/" target="_blank">
                    Sudafed<sup>®</sup> Tabletki
                  </a>
                  , dzięki zawartości{" "}
                  <a href="/slowniczek/#pseudoefedryna" target="_blank">
                    pseudoefedryny
                  </a>
                  , obkurcza naczynia krwionośne, zmniejsza ilość{" "}
                  <a href="/slowniczek/#wydzielina_z_nosa" target="_blank">
                    wydzieliny
                  </a>{" "}
                  w górnych drogach oddechowych, jednocześnie ułatwiając
                  oddychanie i eliminując uczucie ucisku w głowie i zatokach
                  <sup>1</sup>.
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  &nbsp;
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  <span
                    style={{
                      "font-size": "11px",
                    }}
                  >
                    1 Dotyczy uczucia ucisku w zatokach spowodowanego
                    nagromadzoną wydzieliną i obrzękiem błony śluzowej nosa.
                  </span>
                </p>
                <p
                  className="p3"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  &nbsp;
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  <span
                    style={{
                      "font-size": "11px",
                    }}
                  >
                    Bibliografia:
                  </span>
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  <span
                    style={{
                      "font-size": "11px",
                    }}
                  >
                    Górnicka Jadwiga,{" "}
                    <i>Nie daj się przeziębieniu! Receptury natury</i>, AWM
                    Morex, 2014.
                  </span>
                </p>
              </div>
              <div className="other_article">
                <p className="name">Podobne artykuły</p>
                <a
                  href="/poradnik/przeziebienie/cztery_fazy_rozwoju_przeziebienia"
                  className="box"
                >
                  <span
                    className="photo"
                    style={{
                      backgroundImage:
                        'url("/web/kcfinder/uploads/files/shutterstock_87834502.jpg")',
                    }}
                  />
                  <span className="title">
                    Cztery fazy rozwoju przeziębienia
                  </span>
                </a>
                <a
                  href="/poradnik/przeziebienie/jak_uchronic_sie_przed_przeziebieniem"
                  className="box"
                >
                  <span
                    className="photo"
                    style={{
                      backgroundImage:
                        'url("/web/kcfinder/uploads/files/88688757.jpg")',
                    }}
                  />
                  <span className="title">
                    Jak uchronić się przed przeziębieniem?
                  </span>
                </a>
              </div>
            </div>
            <div className="clear" />
          </div>{" "}
        </div>
        <footer>
          <div className="page_width">
            <div className="drug_alert"></div>
            <p
              style={{
                "font-size": "10px",
                "-webkit-text-align": "right",
                "text-align": "right",
                padding: "0 0 10px",
              }}
            >
              MAT/6769/03/2019
            </p>
          </div>
          <div className="footer_content">
            <div className="page_width">
              <ul id="footer_menu">
                <li>
                  <a href="/polityka-prywatnosci/">Polityka prywatności</a>
                </li>
                <li>
                  <a href="/polityka-cookies/">Polityka cookies</a>
                </li>
                <li>
                  <a href="/nota-prawna/">Nota prawna</a>
                </li>
                <li>
                  <a href="/mapa-strony/">Mapa strony</a>
                </li>
                <li>
                  <a href="/slowniczek/">Słowniczek</a>
                </li>
                <li>
                  <a
                    id="ot-sdk-btn"
                    className="ot-sdk-show-settings"
                    rel="nofollow"
                  >
                    {" "}
                    Ustawienia plików Cookie{" "}
                  </a>
                </li>
              </ul>
              <div className="rel footer_info">
                <br />
                Podmiot odpowiedzialny: McNeil Products Limited <br />© JNTL Consumer Health (Poland) sp. z o.o. 2023<br />
                JNTL Consumer Health (Poland) sp. z o.o. w ramach swojej działalności gospodarczej prowadzi hurtowy obrót produktami leczniczymi. Jeżeli jesteś przedsiębiorcą prowadzącym aptekę ogólnodostępną i jesteś zainteresowany nabyciem produktów leczniczych oferowanych przez nas, uprzejma prośba o kontakt z działem Customer Service email: <a href="mailto:apteki@kenvue.com">apteki@kenvue.com</a>. Minimalna wartość zamówienia 500 PLN brutto. Zamówienia realizowane będą wyłącznie po otrzymaniu pełnej przedpłaty.
              </div>
            </div>
          </div>
        </footer>{" "}
      </div>
    );
  }
}

export default Page;
